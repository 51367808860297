.useBalance {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: #fcfcfc;
    border: 1.5px dashed rgba(0, 0, 0, 0.075);
    top: 0;
    bottom: 0;
    left: -15px;
    z-index: 1;
    width: 30px;
    height: 30px;
    margin: auto;
  }

  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: #fcfcfc;
    border: 1.5px dashed rgba(0, 0, 0, 0.075);
    top: 0;
    bottom: 0;
    right: -15px;
    z-index: 1;
    width: 30px;
    height: 30px;
    margin: auto;
  }

  .details {
    border-radius: 25px;
    border: 1.5px dashed rgba(0, 0, 0, 0.1);
    padding: 20px 35px;
    background: #ffffff;

    .name {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #525252;
      margin-bottom: 10px;
    }

    .requirement {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #666666;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .help {
      position: absolute;
      right: 20px;
      top: 0;
      cursor: pointer;
    }
  }
}

.message {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}
