.banners {
  width: 100%;

  @media screen and (min-width: 992px) {
    width: calc(100vw - 465px);
    height: 100%;
  }

  .banner {
    display: flex;
    justify-content: center;
    height: 100vh;
    touch-action: none;

    img {
      width: 100%;
      object-fit: cover;

      @media screen and (max-width: 991px) {
        object-fit: cover;
      }
    }
  }
}
