.bannerContainer {
  width: 100%;
  position: relative;
  margin-top: 60px;
  max-height: 260px;

  @media screen and (min-width: 992px) {
    width: calc(100vw - 5px);
  }

  img {
    width: 100%;
  }

  .prev,
  .next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    width: 47px;
    height: 47px;
    background: #fafafa;
    box-shadow: 2px 10px 32px -9px rgba(15, 15, 15, 0.65);

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .prev {
    left: 50px;

    @media screen and (max-width: 991px) {
      left: 10px;
    }
  }

  .next {
    right: 50px;

    @media screen and (max-width: 991px) {
      right: 10px;
    }
  }
}
