.homeContainer {
  background: #fcfcfc;
  position: relative;
  z-index: 1;

  &.marginTop {
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
  }

  @media screen and (min-width: 992px) {
    width: calc(100vw - 5px);
    padding-top: 15px;
  }
}

.container {
  padding: 30px 15px 45px;
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 991px) {
    padding: 25px 15px;
  }

  .greetings {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #404040;

    @media screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .search {
    margin-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }
  }
}
