.addressMinified {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f2f2f2;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 60px;

  @media screen and (min-width: 992px) {
    display: none;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: rgba(89, 89, 89, 0.95);
    margin-left: 15px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
